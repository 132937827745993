/* TODO: Refactor into css vars */
.chevronRight,
.chevronLeft {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(1);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.chevronRight::after,
.chevronLeft::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  right: 6px;
  top: 4px;
}

.chevronRight::after {
  transform: rotate(-45deg);
}

.chevronLeft::after {
  transform: rotate(135deg);
}
